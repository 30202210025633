.loader {
    margin:auto;
    width:100px;
}

.loaderImage {
    margin:auto;
    max-height:100px;
    display:block;
    animation: spin 2s ease-in-out infinite;
}

.loaderText {
    text-align:center;
}

@keyframes spin {
    50% {
        transform: rotate(180deg) scale(10%);
    }
    100% { 
        transform:rotate(360deg) scale(100%);

    } 
}