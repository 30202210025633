.monthName {
    text-transform: capitalize;
}

.daysContainer {
    display: flex;
    flex-flow: row wrap;
    width: 240px;
}

.day {
    width: 15px;
    height:15px;
    border: 2px solid lightgrey;
    margin: 5px;
    text-align: center;
    padding: 2px;
    font-size: 0.8em;
    color:rgb(13, 13, 13);
    cursor: pointer;
}

.daySuccess {
    background-color: #9ef193;
}

.dayWarn {
    background-color:	#fb9843;
}

.dayErr {
    background-color: 	#ff7575;
}

.dayDed:before {
    color:whitesmoke;
    content:"☠";
    font-size: 25px;
    padding: 0;
    line-height: 15px;
}

.dayDed {
    background-color: black;

}

.dayUnknown {
    background-color: white;
}

.dayDisabled {
    background-color: lightgrey;
    cursor: default;
}

.dayToday {
    border: 2px solid lightsalmon
}

.offset-1 {
    width: 15px;
    height:15px;
    border: 2px solid transparent;
    margin: 5px;
    text-align: center;
    padding: 2px;
    font-size: 0.8em;
}