.dashboard {
    padding: 10px;
}

.clear_btn {
    background:red;
    color: white;
    border:none;
}

.ok_btn {
    background:rgb(1, 187, 119);
    color: white;
    border:none;
}


.monthContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.scoreboard {
    width: 240px;
    max-height: 300px;
    overflow-y: scroll;
}

.username_input {
    padding: 4px;
}

.scoreLine {
    position:relative;
}

.score {
    position: absolute;
    right: 0;
    display: inline-block;
}

.username {
    display:inline-block;
    max-width: 205px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
}